import ajax from './ajax'
import Marker from './marker'

class Map
  constructor: (map) ->
    window.filters = {} unless window.filters
    window.markers = [] unless window.markers
    @setCurrentMap map

  currentMap: ->
    @map

  setCurrentMap: (map) ->
    center = { lat: map.getCenter().lat(), lng: map.getCenter().lng() }
    @setSearchCenter(center)
    @map = map

  setSearchCenter: (searchCenter) ->
    @searchCenter = searchCenter

  getSearchCenter: ->
    @searchCenter

  currentLatitude: ->
    @getSearchCenter().lat #@currentMap().getCenter().lat()

  currentLongitude: ->
    @getSearchCenter().lng #@currentMap().getCenter().lng()

  reverseGeocodeSearchCenter: ->
    geocoder = new google.maps.Geocoder
    geocoder.geocode { 'location': @getSearchCenter() }, (results, status) ->
      console.log results
      console.log status
      if status == 'OK'
        addressComponents = results[0].address_components;
        stateComponent = addressComponents.find (component) -> 'administrative_area_level_1' in component.types
        window.zerostate = if stateComponent['short_name'] == "MN" then 'in-state' else "out-of-state"
        address = results[0].formatted_address
        addressElement = document.getElementsByName('address')[0]
        addressElement.value = address
        if document.getElementById('traffic_log_original_search')
          document.getElementById('traffic_log_original_search').value = address
        filters['search_address'] = address
        map.zerostate = window.zerostate

  clearMarkers: =>
    for marker in window.markers
      marker.setMap(null)
    window.markers = []

  clearSidebar: ->
    sidebar = document.querySelector('#provider-info-sidebar')
    while sidebar.hasChildNodes()
      sidebar.removeChild(sidebar.lastChild);

  setBoundsForMarkers: ->
    if window.markers.length > 0
      @getZeroStateContainer().style.display = "none"
      @getOutOfStateZeroStateContainer().style.display = "none"
      newBoundary = new google.maps.LatLngBounds();
      for marker, index in window.markers
        break if index > 20
        pos = marker.position
        newBoundary.extend(pos)
      @currentMap().fitBounds(newBoundary)
      if (map.map.getZoom() > 16)
        map.map.setZoom(16)
        map.map.initialZoom = false;
    else
      setTimeout ((e) =>
        @displayZeroState()
      ), 300


  removeListener: ->
    google.maps.event.removeListener(window.mapChangelistener);

  setCenter: (lat, lng)->
    @setSearchCenter({ 'lat': lat, 'lng': lng })
    @currentMap().setCenter(@getSearchCenter())

  addSearchCenterMarker: ->
    icon = "/static/images/search_address_pin.png"
    marker = { icon: icon, position: @getSearchCenter() }
    window.centerMarker.setMap(null) if window.centerMarker # Clear out existing center marker
    window.centerMarker = new google.maps.Marker marker
    window.centerMarker.setMap(@currentMap())

  getZeroStateContainer: ->
    document.getElementById('map-zero-state')

  displayZeroState: =>
    checkForZeroState = =>
      if window.zerostate?
        if window.zerostate == 'out-of-state' && !window.text_search_address
          @getZeroStateContainer().classList.remove('show')
          @getOutOfStateZeroStateContainer().classList.add('show')
        else
          @getOutOfStateZeroStateContainer().classList.remove('show')
          @getZeroStateContainer().classList.add('show')
      else
        setTimeout(checkForZeroState, 100)
    checkForZeroState()

  getOutOfStateZeroStateContainer: ->
    document.getElementById('map-zero-state-out-of-state')

  showMapLoadingState:(loading) ->
    mapLoadingState = document.getElementById('map-loading-state')
    mapContainer = document.getElementById('map')
    mapLegend = document.getElementById('map-legend')

    if (loading)
      mapLoadingState.style.display = 'block'
      mapContainer.style.display = 'none'
      mapLegend.style.display = 'none'
    else
      mapLoadingState.style.display = 'none'
      mapContainer.style.display = 'block'
      mapLegend.style.display = 'flex'

  updateMap: (forceSetBounds = false) ->
    @showMapLoadingState(true)
    path = ""
    query = queryString.stringify(window.filters, { arrayFormat: 'bracket' })

    if window.text_search_address
      path = "/providers?#{query}&text_search=#{window.text_search_address}"
    else
      path = "/providers?current_latitude=#{this.currentLatitude()}&current_longitude=#{this.currentLongitude()}&#{query}"
    this.clearMarkers() if window.markers
    ajax.getAjax path, (data) =>
      jsonData = JSON.parse(data)
      @clearSidebar()
      for provider in jsonData
        marker = new Marker(provider, @currentMap())
        marker.addMarkerFor(provider)
      @showMapLoadingState(false)
      @addSearchCenterMarker()
      @setBoundsForMarkers() if !window.mapChangelistener || forceSetBounds

export default Map
