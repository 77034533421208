import Humanize from './humanize'
class FilterProcessor
  constructor: ->
    window.filters = {} unless window.filters
    if (address = @queryStringToObj()['search_address'])
      @applyAddressFromParams(address)

    for [key, value] in Object.entries(@queryStringToObj())
      @applyFilterFromParams(key, value)

  queryStringToObj: ->
    queryString.parse(location.search)

  applyAddressFromParams: (address) ->
    # Apply existing search from query.
    addressElement = document.getElementsByName('address')[0]
    addressElement.value = address
    # addressElement.parentNode.children.namedItem('address-find-button').click()
    window.filters['search_address'] = address
    @geocodeAndRecenterOn(address)

  applyFilterFromParams: (key, value) ->
    elements = Array.from(document.querySelectorAll('input[type="checkbox"], input[type="radio"]'))
    for element in elements
      if element.name == key && element.value == value
        window.filters[key] = value
        element.checked = true

  addCheckboxFilter: (filterElm) =>
    map.removeListener()
    if window.filters[filterElm.name] == undefined
      window.filters[filterElm.name] = []
    if filterElm.checked and window.filters[filterElm.name].indexOf(filterElm.value) is -1
      window.filters[filterElm.name].push filterElm.value
    else
      index = window.filters[filterElm.name].indexOf(filterElm.value)
      if index > -1
        window.filters[filterElm.name].splice(index, 1);
    if window.filters[filterElm.name].length == 0
      delete window.filters[filterElm.name]
    shouldUpdateMap = filterElm.dataset.updatemap
    if shouldUpdateMap == true
      map.updateMap(true)

  window.updateMapWithCurrentFilters = () ->
    if window.innerWidth < 992
      window.toggleMobileFilters()
    map.updateMap(true)

  window.toggleMobileFilters = () ->
    providerFilters = document.getElementById('provider-filters')
    if providerFilters
      providerFilters.classList.toggle('mobile-filters-active')
      if document.body.style.overflow = 'hidden'
        document.body.style.removeProperty('overflow')

  window.clearFilters = () ->
    if window.innerWidth < 992
      window.toggleMobileFilters()
    filterContainer = document.getElementById('provider-filters')
    filters = Object.keys(window.filters)
    filterContainer.classList.remove('filters-active')
    delete window.filters[key] for key of window.filters when key not in ['hours', 'search_address']
    checkboxes = document.querySelectorAll('input[type="checkbox"], input[type="radio"]')
    checkboxes.forEach((checkbox) ->
        if checkbox.checked
            checkbox.checked = false
    )
    filters = document.querySelectorAll(".filter")
    filters.forEach((filter) ->
      filter.style.border = "unset"
    )
    filterCounter = document.querySelectorAll('.filter-counter')
    filterCounter.forEach((filtercount) ->
        filtercount.textContent = ""
    )
    if event.currentTarget.id == 'clear-filters'
      event.currentTarget.classList.toggle('d-none')
    map.updateMap(true)
    unless document.querySelector('#clear-filters').classList.contains('d-none')
      document.querySelector('#clear-filters').classList.add('d-none')

  setAddress: (formElm, event) ->
    event.preventDefault() if event
    address = new FormData(formElm).get('address')
    window.filters['search_address'] = address
    if ( document.getElementById('traffic_log_original_search') )
      document.getElementById('traffic_log_original_search').value = address
    @geocodeAndRecenterOn(address)

  geocodeAndRecenterOn: (address) ->
    geocoder = new (google.maps.Geocoder)
    geocoder.geocode { 'address': address }, (results, status) ->
      if (status == google.maps.GeocoderStatus.OK)
        window.text_search_address = null
        loc = results[0].geometry.location
        sscache.set(address, loc)
        addressComponents = results[0].address_components;
        stateComponent = addressComponents.find (component) -> 'administrative_area_level_1' in component.types
        window.zerostate = if stateComponent['short_name'] == "MN" then 'in-state' else "out-of-state"
        lat = loc.lat()
        lng = loc.lng()
        map.setCenter(lat, lng)
        map.updateMap(true)
        window.latlng = {lat: lat, lng: lng}
        if ( document.getElementById('traffic_log_latlng') )
          document.getElementById('traffic_log_latlng').value = lat + "," + lng
      else
        window.text_search_address = address
        map.updateMap(true)

export default FilterProcessor
