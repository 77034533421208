import InfoWindow from './info_window'
class Marker
  constructor: (provider, map) ->
    @map = map
    @provider = provider

  currentMap: ->
    @map

  addMarkerFor: (provider) ->
    infoNode = @buildInfoNodeFor(provider)
    icon = @iconFor(provider)
    marker = @addMarker({ id: provider.id, icon: icon, position: { lat: provider.latitude, lng: provider.longitude } })
    @addInfoWindowFor(provider, marker, infoNode)

  addMarker: (marker) ->
    newMarker = new google.maps.Marker marker
    newMarker.setMap(@currentMap())

    scrollContainer = document.getElementById('provider-info-sidebar')
    newMarker.addListener 'click', (e) =>

      providerElement = document.getElementById("provider-id-#{marker.id}")

      # Scroll for desktop
      scrollSource = providerElement.parentElement
      scrollSource.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });

      # Highlight
      providerElement.parentNode.classList.add('highlight');

      setTimeout ((e) ->
        providerElement.parentNode.classList.remove('highlight');
      ),500

    window.markers.push newMarker
    return newMarker

  iconFor: (provider) ->
    # Maps directly to enum
    pins = ["/static/images/daycare_center_pin.png", "/static/images/daycare_home_pin.png"]
    pins[provider.state_license_type]
    # pins = "/static/images/daycare-pin-updated.png"
  buildInfoNodeFor: (provider) ->
    domID = "provider-id-#{provider.id}"
    unless document.getElementById(domID)
      instance = new InfoWindow(provider).getSidebarNode(domID)

      document.querySelector('#provider-info-sidebar').appendChild(instance)
    return { htmlNode: instance, id: provider.id, domID: domID }

  closeCurrentPin: ->
    if window.currentPin
      window.currentPin.infowindow.close(@currentMap(), window.currentPin.marker)
      window.currentPin = null

  addInfoWindowFor: (provider, marker, infoNode) ->
    instance = new InfoWindow(provider).getMarkerNode()
    content = instance.querySelector('.marker-provider-info-wrapper')

    infowindow = new google.maps.InfoWindow({ content: content })
    sidebarInfoNode = document.getElementById(infoNode.domID)

    # Force show to inspect dom
    #infowindow.open(@currentMap, marker)
    #foobar

    sidebarInfoNode.addEventListener 'click', (e) =>
      if screen? && window.innerWidth > 992
        @closeCurrentPin()
        window.currentPin = { infowindow: infowindow, marker: marker }
        infowindow.open(map: @currentMap, anchor: marker, shouldFocus: false)

    marker.infowindow = infowindow

    marker.addListener 'click', =>
      @closeCurrentPin()

      window.currentPin =  { infowindow: infowindow, marker: marker }
      infowindow.open(map: @currentMap(), anchor: marker, shouldFocus: false)

export default Marker
