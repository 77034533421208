import FilterProcessor from './filter_processor'
import Map from './map'

class MapHandler
  constructor: ->
    if (searchAddress = queryString.parse(location.search)['search_address'])
      if (cachedAddress = sscache.get(searchAddress))
        console.log(cachedAddress)
        @geoSuccess({ coords: { latitude: cachedAddress.lat, longitude: cachedAddress.lng}}, false)
      else
        @geoFailure()
    else
      @setCenterByGeoLocation()

  setCenterByGeoLocation: ->
    # reset text search for page navigation reload
    window.text_search_address = null

    if (cachedGeolocation = sscache.get('cachedGeolocation'))
      @geoSuccess(cachedGeolocation)
    else
      if navigator.geolocation
        navigator.geolocation.getCurrentPosition(@geoSuccess, @geoFailure, { maximumAge: 1800000 })
      else
        @geoFailure(null)

  geoSuccess: (position, setReverse = true) =>
    lat = position.coords.latitude
    lng = position.coords.longitude

    @buildMap({ lat: lat, lng: lng })
    window.map = new Map(window.googleMap)
    map.reverseGeocodeSearchCenter() if setReverse
    map.updateMap(true)
    sscache.set('cachedGeolocation', { coords: { latitude: lat, longitude: lng}}, 15)
    document.getElementById("traffic_log_latlng").value = "#{lat}, #{lng}"
    window.latlng = {lat: lat, lng: lng}

  geoFailure: (error) =>
    if (cachedGeolocation = sscache.get('cachedGeolocation'))
      @geoSuccess(cachedGeolocation)
    else
      lat = 46.7296
      lng = -94.6859
      @buildMap({ lat: lat, lng: lng })
      window.map = new Map(window.googleMap)
      map.updateMap(true)

  buildMap: (center) =>

    styles = [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }],
      }, {
        featureType: "poi.business",
        stylers: [{
          visibility: "off"
        }],
      }, {
        featureType: "poi.attraction",
        stylers: [{
          visibility: "off"
        }],
      }, {
        featureType: "transit",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }],
      },
    ];

    @map = new google.maps.Map(document.getElementById('map'),
      { zoom: 5, center: center, styles: styles }
    )
    window.googleMap = @map

window.mapHandler = new MapHandler()
window.map = new Map(window.googleMap) if window.googleMap
window.filterProcessor = new FilterProcessor()
